const uiText = {
  login: {
    subTitle: 'sign in to continue',
    email_label: 'Email Address',
    password_label: 'Password',
    forgotten_label: `I've forgotten my password...`,
    submit_label: 'Continue',
    register_label: 'Register',
  },
  register: {
    subTitle: 'Register',
    description: '',
    email_label: 'Email address',
    password_label: 'Password',
    reference_label: 'Reference',
    postcode_label: 'Postcode',
    terms_condition_label: 'I accept the terms and conditions',
    submit_label: 'Submit',
    link_label: '< Login with an existing account',
  },
  reset: {
    subTitle: `I've forgotten my password`,
    lastname_label: 'Last Name',
    email_label: 'Email address',
    password_label: 'New password',
    reference_label: 'Reference*',
    postcode_label: 'Postcode',
    submit_label: 'Continue',
    link_label: '< Back',
  },
  authenticate: {
    subTitle: `Enter One Time Code`,
    lastname_label: 'Last Name',
    code_label: 'Auth Code',
    submit_label: 'Continue',
    link_label: '< Back',
  },
  errors: {
    reset: 'credentials are invalid',
    unauthorized:
      'Your username or password is incorrect. Try to reset your password',
    already_activated: 'User already activated',
    invalid_credentials: 'Invalid credentials, please try again',
    error_activate: 'Unable to activate account. Please try again',
    email: {
      required: 'Email is a required field',
      invalid: 'Invalid email format',
    },
    password: {
      required: 'Password is a required field',
      invalid: 'Password must be at least 8 characters',
    },
    firstName: {
      required: 'First Name is a required field',
      invalid: 'First Name must be at least 2 characters',
    },
    lastName: {
      required: 'Last Name is a required field',
      invalid: 'Last Name must be at least 2 characters',
    },
    reference: {
      required: 'Reference is a required field',
      invalid: 'Invalid reference format',
    },
    postcode: {
      required: 'Postcode is a required field',
      invalid: 'Invalid postcode format',
    },
    projectName: {
      required: 'Project Name is a required field',
      invalid: 'Invalid Project Name format',
    },
    projectNumber: {
      required: 'Project Number is a required field',
      invalid: 'Invalid Project Number format',
    },
    homePhone: {
      required: 'Phone Number is a required field',
      invalid: 'Invalid Phone Number format',
    },
    mobile: {
      required: 'Mobile Number is a required field',
      invalid: 'Invalid Mobile Number format',
    },
    projectLocation: {
      required: 'Project Location is a required field',
      invalid: 'Invalid Project Location format',
    },
    projectStartDate: {
      required: 'Project Start date is a required field',
      invalid: 'Invalid Project Start Date format',
    },
    projectEndDate: {
      required: 'Project End Date is a required field',
      invalid: 'Invalid Project End Date format',
    },
    projectPhase: {
      required: 'Project Phase is a required field',
      invalid: 'Invalid Project Phase format',
    },
    LTIRMin: {
      invalid: 'Invalid LTIR Min format',
    },
    LTIRMax: {
      invalid: 'Invalid LTIR Max format',
    },
    TRIRMin: {
      invalid: 'Invalid TRIR Min format',
    },
    TRIRMax: {
      invalid: 'Invalid TRIR Max format',
    },
    DARTMin: {
      invalid: 'Invalid DART Min format',
    },
    DARTMax: {
      invalid: 'Invalid DART Max format',
    },
  },
  message: {
    activate: 'Your account has been created successfully',
    reset:
      'If the details you provided are correct your password will have been reset',
  },
  addedUser: 'User added successfully',
  editUser: 'User edited successfully',
  addClient: 'Client added successfully',
}
export default uiText
