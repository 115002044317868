import React, { useContext, useEffect, useState } from 'react'
import Typography from '../../../../ui-library/components/Typography'
import Dropdown from '../../../../ui-library/components/Dropdown'
import Button from '../../../../ui-library/components/Button'
import DashboardContainer from '../../../../ui-library/components/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import Paper from '@mui/material/Paper'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import { getAppUsers } from '../../../../handlers/requests/users/appUsersHandler'
import {
  deleteGDPRData,
  getGDPRData,
} from '../../../../handlers/requests/users/gdpr'

const Gdpr = () => {
  const { theme } = useContext(ThemeContext)
  const [userData, setUserData] = useState([])
  const [selectedUser, setSelectedUser] = useState('')
  const [selectedDeleteUser, setSelectedDeleteUser] = useState('')
  useEffect(() => {
    const appUsers = async () => {
      const users = await getAppUsers('')
      setUserData(users?.data)
    }
    appUsers()
  }, [])
  return (
    <DashboardContainer>
      <TabHeader title="GDPR Centre" />
      <Paper style={theme?.dashboard?.container}>
        <Typography source={'h2'}>Download User Data</Typography>
        <Typography source={'paragraph'}>
          This is used to easily generate and download a CSV file containing user data in response to information access requests, ensuring seamless GDPR compliance.
        </Typography>
        <Dropdown
          items={userData?.map((user) => ({
            label: user?.firstname + ' ' + user?.lastname,
            value: user?.id,
          }))}
          setSelected={setSelectedUser}
        />
        <Button
          onClick={async () => {
            const response = await getGDPRData(selectedUser?.value)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'gdpr.csv')
            document.body.appendChild(link)
            link.click()
          }}
        >
          Download All User Data
        </Button>
        <Typography source={'h2'}>Delete User Data</Typography>
        <Typography source={'paragraph'}>
          This enables you to promptly remove personal information for users who have requested deletion, helping you stay compliant with GDPR regulations.
        </Typography>
        <Typography>All Data will be permanently deleted</Typography>
        <Dropdown
          items={userData?.map((user) => ({
            label: user?.firstname + ' ' + user?.lastname,
            value: user?.id,
          }))}
          setSelected={setSelectedDeleteUser}
        />
        <Button
          onClick={async () => {
            await deleteGDPRData(selectedDeleteUser?.value)
          }}
        >
          Delete All User Data
        </Button>
      </Paper>
    </DashboardContainer>
  )
}

export default Gdpr
